.join-us{
    width: 100%;
    min-height: 100px;
    padding: 30px 0;
    float: left;
    color:#000;
    text-align: center;
    background-image: linear-gradient(134deg,#99034e 0,  #cd0066 50%,  #cd0066 55%,  #cd0066 100%);
}
.join-us ul {
    list-style: none;
    margin-bottom: 0;
}
.vid{
    padding-top: 30px;
    padding-bottom: 30px;

}
.cinema{
    background-color: #000;
}
.join-us ul li {
    float: left;
    margin-left: 10px;
}
.join-us h3, .join-us p {
    color:#fff;
}
.join-us p {
font-family: "Alegreya Sans";
font-size: 18px;
font-weight: 500;
}
.join_btn{
    background: none repeat scroll 0 0 #cd0066;
        color: #fff !important;
        display: inline-block;
        height: 47px;
        outline: none;
        border: none;
        position: relative;
        text-align: center;
        padding-top:5px;
        text-transform: uppercase;
        transform: translateZ(0px);
        transition-duration: 0.3s;
        transition-property: color;
        vertical-align: middle;
        width: 150px;
}