.top-nav {
  width: 100%;
  float: left;
  transition: all 0.5s ease;
  background-image: linear-gradient(134deg,#99034e 0,  #cd0066 50%,  #cd0066 55%,  #cd0066 100%);
  background-size: 200% 100%;
}
.nav-show {
  height: 50px;
}
.nav-noshow {
  height: 0px;
}
.topsearch {
  margin-top: 7px;
  background-color: #99034e;
  outline: none;
  border: none;
  color: #fff;
  width: 85%;
  font-size: 16px !important;
  height: 30px !important;
}

.topsearch::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
  opacity: 0.6;
  font-size: 17px;
}

.topsearch:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  opacity: 0.6;
  font-size: 17px;
}

.topsearch::placeholder {
  color: #fff;
  opacity: 0.6;
  font-size: 17px;
}

.topsearch:focus {
  border: none;
}

.topsocial ul {
  list-style: none;
  color: #cd0066;
  float: right;
}
.fa {
  font-size: 19px;
  color: #fff;
}

.topsocial ul li {
  width: 25px;
  height: 27px;
  float: left;
  margin-top: 9px;
  margin-left: 6px;
  padding-top: 8px;
  padding-left: 11px;
}
.totop {
  position: fixed;
  bottom: 20px;
  background: #cd0066;
  width: 43px;
  height: 37px;
  z-index: 54;
  padding-top: 5px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  transition: all 0.7s ease;
  right: -50px;
  opacity: 0.9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
.totop-show {
  right: 20px;
}

.arrow {
  transform: rotate(180deg);
}
.auto_search {
  position: absolute;
  height: 0px;
  background-color: #fff;
  width: 48%;
  top: 65%;
  z-index: 1000;
  transition: all 0.5s ease;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  display: block;
  
  overflow: hidden;
}

.auto_search h5 {
  margin-bottom: 10px;
}
.auto_content{
  padding:20px;
  width:100%;
  float: left;
}

.new_auto {
  height: 220px;
}
