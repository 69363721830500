.page_intro {
  height: 420px;
  background-color: rgb(28, 24, 66, 0.4);
  background-repeat: no-repeat;
  background-size: cover;
}

.page_intro:before {
  content: "";
  width: 100%;
  height: 420px;
  background-color: #000;
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 110px;
}

.page_intro h3 {
  color: #fff;
  font-size: 5rem;
  opacity: 1;
  text-align: center;
  position: relative;
  text-transform: capitalize;
  padding-top: 15%;
}
