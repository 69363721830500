footer{
    width: 100%;
    float: left;
    height: auto;
    background-color:#131212;
    padding-top: 70px;
    color:rgba(133, 130, 130, 0.8);
    text-transform: uppercase;
}
.foot h4{
    color:rgba(180, 179, 179, 0.801);
    font-size: 18px;
    margin-bottom: 3rem;
    border-bottom: 5px solid   #cd0066;
    border-right: 5px solid #131212;
    padding-bottom: 15px;

}
footer ul li{
    list-style: none;
    font-size: 15px;
    text-transform: capitalize;
}

footer ul a li{
    text-decoration: none;
    color:rgba(133, 130, 130, 0.8);
}

footer ul a li:hover{
    text-decoration: none;
    color:  #cd0066 !important
}

footer ul a{
    text-decoration: none;
}
footer ul li i{
    margin-right: 10px;
    margin-top: 5px;
}
.foot_social{
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    float: none;
}

.foot_social li{
    float: left;
    margin-left: 20px;
    background-color: #131212;
    padding-left: 13px;
    padding-top: 10px;
    width: 27px;
    height: 30px;
    border-radius: 50%;
}
.copyright{
    text-align: center;
    font-size: 16px;
    margin-bottom: 0;
    padding: 20px 0;
    border-top: 1px solid #232222;
}
.copyright span{
    color:  #cd0066;
}