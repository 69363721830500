.bot-nav {
  width: 100%;
  float: left;
  background-color: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35);
}

.logo {
  position: absolute;
  margin-top: -50px;
  padding-top: 3rem;
  box-shadow: 0px 0px 4px 0px #666;
  background-color: #fff;
  height:130px;
  vertical-align: middle;
  text-align: center;
  transition: all 1s ease;
}

.max{
  height: 130px;
  margin-top: -50px;
  padding-top: 2rem;
  margin-left: -10px;
}

.min{
  margin-top:0 !important;
  height: 80px;
  padding-top:1.5rem !important;
  margin-left: -10px;
}

.nav ul li {
  float: left;
  padding: 6px 12px;
  margin-bottom: 0;
  transition: all 1s ease;
}
.nav ul li:hover {
  color: #fff;
  cursor: pointer;
  background-color: #600a40;
}
.donate_btn {
  backface-visibility: hidden;
  background: none repeat scroll 0 0#cd0066;
  color: #fff !important;
  display: inline-block;
  height: 32px;
  outline: none;
  border: none;
  text-align: center;
  margin-left: 10px;
  padding-top: 5px;
  text-transform: uppercase;
  transform: translateZ(0px);
  transition-duration: 0.3s;
  transition-property: color;
  vertical-align: middle;
  width: 50% !important;
  padding-top: 6px !important;
  padding-bottom: 8px !important;
}
.nav ul {
  width: 100%;
  float: left;
  padding-top: 8px;
  font-family: "Alegreya Sans";
  background-color: #fff;
  list-style: none;
  text-transform: uppercase;
  font-size: 17px;
  line-height: 34px;
  padding-left: 40px;
  margin-bottom: 0;
  padding-bottom: 8px;
}

.nav ul li:hover > ul {
  display: block;
}
.nav{
  transition: all 2s ease;
}
.nav ul ul {
  position: absolute;
  background-color: #fff;
  width: 240px;
  display: none;
  z-index: 100;
  float: left;
  list-style: none;
  text-transform: capitalize;
  margin-left: -12px;
  padding-left: 0px;
  font-size: 18px;
  line-height: 24px;
  padding-top: 10px;
  margin-top: 5px;
  margin-bottom: 0;
  padding-bottom: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.nav ul ul li {
  float: left;
  width: 80%;
  padding: 7px 10%;
  background-clip: padding-box;
  font-size: 17px;
  margin-bottom: 0;
  color: #000;
}

.nav ul ul li:hover {
  background-color: #efefef;
  color:   #cd0066;
}
.nav-max{
  height: 350px !important;
  overflow: hidden;
}

.nav-min{
  height: 0;
  overflow: hidden;
}
.mobile{
  width: 43px;
  height: 35px;
  margin-top: 12px;
  display: block;
  float: right;
  cursor: pointer;
  margin-bottom: 13px;
}

.icon-bar {
  display: block;
  width: 32px;
  background-color: #B9317D;
  margin-top: 7px;
  margin-left: 5px;
  height: 2px;
  border-radius: 1px;
}
