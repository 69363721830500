.rotator {
  width: 100%;
  background-color: #000;
  height: 500px;
  overflow: hidden;
}
.descrip {
  background-color: rgb(205, 0, 102,0.55);
  color:#fff;
  height: auto;
  position: absolute;
  bottom: 35%;
  padding: 20px;
  z-index: 50;
}

.descrip p{
   font-size: 2rem;
   line-height: 1.3em;
   text-align: center;
   margin-bottom:5px ;
}


.descrip h2{
  font-size: 34px;
  text-align: center;
  margin-bottom: 5px !important ;
}

.img_details {
  padding: 15px;
  float: left;
  font-size: 21px;
  text-align: center;
}
