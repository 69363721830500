.donate-seg{
    height: 55px;
    text-transform: uppercase;
    text-align: center;
    background: #FFF;
    color: #16a4c5;
    border: 3px solid #16a4c5;
    line-height: 50px;
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px;
}

.active{
    background-color: #16a4c5 !important;
    color:#fff !important
}
.inactive{
    background-color: #fff;
}
.amount{
    color: #16a4c5;
    font-weight: bold;
    font-size: 24px;
    padding-top: 20px;
}

.donate-input{
    border: 1px solid #16a4c5 !important;
    margin-top: 20px;
    font-size: 28px;
}
.donate-seg:hover{
    background-color: #16a4c5;
    color:#fff;
    cursor: pointer;
}
.page{
    height: 50px;
    margin-top: 20px;
    float: right; 
}