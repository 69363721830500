.formfield{
    border: 1px solid #16a4c5 !important;
    width: 100%;
}
label{
    font-weight: normal !important;
    color:#8b8888
}
.vol_btn{
    width:100%;
    height:42px;
    background: none repeat scroll 0 0 #cd0066;
    color: #fff !important;
    border:none;
    outline: none;
    margin-top: 30px;
    margin-bottom: 50px;

}
.alert_success{
    height: 60px;
    color:#090;
    text-align: center;
}

.show{
    display: block;
}

.hide{
    display: none;
}