.staff{
    height:auto;
    float:left !important; 
    width:200px !important;
    float: left;
}
.name{
    margin-bottom: 0;
    text-align: center;
    float:left;
    width:100%;
    font-size: 17px;
    margin-top:5px;
}
.staff a{
    background-color: transparent !important;
    color:#cd0066
}

.image{
    width:100%;
    height:200px; 
    float: left;
    font-size: 18px;
    overflow: hidden;
    border-radius: 50%;
}
.position{
    width:100%;
    float: left;
    height: 50px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase !important;
}